import { useEffect } from "react";
import {
  useGetExercisesMutation,
  useCheckAnswersMutation,
  useCheckGuestAnswersMutation,
  useLatestExercisesMutation,
  useLatestGuestExercisesMutation,
  useGetSingleExerciseMutation,
  useGetUserAnswersMutation,
  useGetAnswersMutation,
  useGetExercisesForGuestMutation,
} from "./exercisesApi";
import { useDispatch, useSelector } from "react-redux";
import { setExercise, setUserAnswers, setInitial } from "./exerciseSlice";
import { selectExerciseParams } from "./exerciseSelectors";
import { useAnalytics } from "../../../shared/hooks/useAnalytic";

export const useExercise = ({ notificationApi }) => {
  const dispatch = useDispatch();
  const exerciseList = useSelector((state) => state.exercise.exercise);
  const request_id = useSelector((state) => state.exercise.request_id);
  const userAnswers = useSelector((state) => state.exercise.user_answers);
  const initialData = useSelector(selectExerciseParams);
  // const someerror = useSelector((state) => state.exercise.error);
  const { sendEvent } = useAnalytics();
  const [triggerGetUserAnswers] = useGetUserAnswersMutation();

  const [
    triggerExercises,
    {
      error: exercisesError,
      isFetching: isFetchingExercises,
      isLoading: isLoadingExercises,
    },
  ] = useGetExercisesMutation();

  const [checkAnswersTrigger, { data: checkedAnswers, reset: resetAnswers }] =
    useCheckAnswersMutation();
  const [
    checkAnswersGuestTrigger,
    { data: checkedQuestAnswers, reset: resetGuestAnswers },
  ] = useCheckGuestAnswersMutation();
  const [getLatestExercise] = useLatestExercisesMutation();
  const [getLatestGuestExercise] = useLatestGuestExercisesMutation();
  const [getExercise] = useGetSingleExerciseMutation();

  const [getExerciseForGuest, { error, isLoading: isLoadingGuestExercises }] =
    useGetExercisesForGuestMutation();

  const [getAnswers, { data: answers }] = useGetAnswersMutation();

  const resetAll = () => {
    resetAnswers();
    resetGuestAnswers();
  };

  const clearState = () => {
    dispatch(setInitial());
  };

  const requestUserAnswers = async ({ request_id }) => {
    try {
      const responseAnswer = await triggerGetUserAnswers({
        request_id,
      }).unwrap();

      dispatch(setUserAnswers(responseAnswer));
    } catch (error) {}
  };

  const generateExercise = async ({ level, tenses }) => {
    sendEvent({
      action: "generate_exercise",
      category: "click",
      label: "Exercise Generator",
    });
    try {
      const response = await triggerExercises({
        level,
        tenses,
        count: 10,
      }).unwrap();

      dispatch(
        setExercise({
          exercise: response.exercise,
          request_id: response.request_id,
        })
      );
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (error) {
      const errorMessage = {
        429: "You have 5 requests per day for unregistered users",
      };

      notificationApi.error({
        message: error.data?.message,
        description: errorMessage[error.status] || "",
        placement: "bottomRight",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onGetLatestExercise = async (user_id) => {
    try {
      const response = await getLatestExercise({ user_id }).unwrap();
      requestUserAnswers({ request_id: response.id });
      dispatch(
        setExercise({
          exercise: response.exercises,
          request_id: response.id,
          level: response.level,
          tense: response.tense,
        })
      );
    } catch (error) {
      // notificationApi.error({
      //   message: error.data.detail || "Something whent wrong",
      //   placement: "bottomRight",
      // });
    }
  };

  const onGetLatestGuestExercise = async () => {
    try {
      sendEvent({
        action: "get_latest_exercise",
        category: "view",
      });

      const response = await getLatestGuestExercise().unwrap();
      requestUserAnswers({ request_id: response.id });
      dispatch(
        setExercise({
          exercise: response.exercises,
          request_id: response.id,
          level: response.level,
          tense: response.tense,
        })
      );
    } catch (error) {
      if (error.status === 404) {
        getGuestExercise({ level: "B1", tenses: ["Present Simple"] });
      }
    }
  };

  const handleCheckAnswers = async (user_answers) => {
    try {
      if (!request_id) {
        return;
      }
      sendEvent({
        action: "check_answers",
        category: "click",
      });
      const result = await checkAnswersTrigger({
        request_id,
        user_answers,
      }).unwrap();

      return result;
    } catch (error) {
      notificationApi.error({
        message: error.data.detail || "Something whent wrong",
        placement: "bottomRight",
      });
    }
  };

  const handleCheckGuestAnswers = async (user_answers) => {
    try {
      if (!request_id) {
        return;
      }
      sendEvent({
        action: "check_guest_answers",
        category: "click",
      });
      const result = await checkAnswersGuestTrigger({
        request_id,
        user_answers,
      }).unwrap();

      return result;
    } catch (error) {
      notificationApi.error({
        message: error.data.detail || "Something whent wrong",
        placement: "bottomRight",
      });
    }
  };

  const onGetAnswers = async () => {
    if (!request_id) {
      return;
    }
    sendEvent({
      action: "get_answers",
      category: "click",
    });
    try {
      getAnswers({ request_id });
    } catch (error) {
      console.log(error);
    }
  };

  const onLoadExercise = async (request_id) => {
    try {
      const response = await getExercise({ request_id }).unwrap();
      sendEvent({
        action: "load_exercise",
        category: "click",
      });
      requestUserAnswers({ request_id });
      dispatch(
        setExercise({
          exercise: response.exercises,
          request_id: response.request_id,
          level: response.level,
          tense: response.tense,
        })
      );
    } catch (error) {}
  };

  const getGuestExercise = async ({ level, tenses }) => {
    sendEvent({
      action: "generate_exercise_guest",
      category: "click",
      label: "Exercise Generator",
    });
    try {
      const response = await getExerciseForGuest({
        level,
        tenses,
        count: 10,
      }).unwrap();

      dispatch(
        setExercise({
          exercise: response.exercise,
          request_id: response.request_id,
          level: response.level,
          tense: response.tense,
        })
      );
    } catch (error) {
      console.log(error, "error");
    }
  };

  return {
    isFetchingExercises,
    isLoadingExercises: isLoadingExercises || isLoadingGuestExercises,
    generateExercise,
    getGuestExercise,
    handleCheckAnswers,
    handleCheckGuestAnswers,
    onGetLatestExercise,
    onGetLatestGuestExercise,
    onGetAnswers,
    answers,
    resetAll,
    clearState,
    request_id,
    error: exercisesError,
    exercise: exerciseList || [],
    correct_answers:
      checkedAnswers?.correct_answers || checkedQuestAnswers?.correct_answers,
    total: checkedAnswers?.total_count || checkedQuestAnswers?.total_count,
    correct_count:
      checkedAnswers?.correct_count || checkedQuestAnswers?.correct_count,
    onLoadExercise,
    initialData: initialData,
    user_answers: userAnswers,
  };
};
