/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/user/module/userSlice";

export const useAnalytics = () => {
  const location = useLocation();
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    user &&
      ReactGA.set({
        user_id: user.user_id,
      });

    user &&
      ReactGA.event({
        action: "login",
        category: "auth",
      });
  }, [user]);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      console.log(ReactGA.isInitialized, "ReactGA.isInitialized");
    } else {
      ReactGA.initialize("G-WW6BNRS64X", { debug_mode: true });
    }
  }, []);

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location.pathname, location.search]);

  const sendEvent = ({ action, category, label }) => {
    ReactGA.event({
      action,
      category,
      label,
    });
  };

  return {
    sendEvent,
  };
};
